import React from 'react';
import { useRouter } from 'next/router';
// import Landing from '../components/home/Landing';

function Home() {
  const router = useRouter();
  React.useEffect(() => {
    router?.push('/sign-in');
  });
  return <div />;
}

export default Home;
