import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { User } from '../models';
import { CURRENT_USER } from '../graphql/queries';
import Loading from '../components/shared/Loading';
import AppContext from './index';

interface AppContextProvider {
  children: React.ReactChild | React.ReactChild[];
}

const AppContextProvider = ({ children }: AppContextProvider) => {
  const router = useRouter();
  const {
    loading: loadingUser,
    data: dataUser,
    error: errorUser,
  } = useQuery<{
    me: User;
  }>(CURRENT_USER);

  const [user, setUser] = React.useState<User>({});
  React.useEffect(() => {
    if (!loadingUser) {
      if (dataUser) {
        setUser(dataUser.me);
      }
      if (errorUser) {
        console.log(errorUser);
        setUser(null);
      }
    }
  }, [loadingUser, dataUser, errorUser]);

  // React.useEffect(
  //   function redirectHook() {
  //     if (!user && router.asPath.includes('/app')) {
  //       router.push('/sign-in');
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [user]
  // );
  return (
    <AppContext.Provider value={{ user, setUser }}>
      {loadingUser ? <Loading loading={loadingUser} /> : children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
